@import './swac-variables';
// primeng components generally don't read ng component-scoped classes
.invalid-full-border {
  .ng-dirty.ng-invalid {
    border-color: #f44336;
  }
}

p-calendar.ng-dirty.ng-invalid,
p-autocomplete.ng-dirty.ng-invalid {
  .invalid-full-border {
    border-color: #f44336;
  }
}

div.no-ul-border {
  ul {
    border: none;
  }
}

.dd {
  width: 12rem;
}

.full-width {
  width: 100%;
}

.center-col {
  text-align: center !important;
}

.add-to-clinic {
  &.p-button {
    background: #549ed5;
    border: none;
    &:enabled:hover,
    &:focus {
      border: none;
      background: darken(#549ed5, 20%);
    }
    .p-button-label {
      font-size: 0.8rem;
    }
  }
}

.capacity-assistance {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0.7em 0.5em 0 0.5em;

  &.p-button {
    width: 100%;
    background: #549ed5;
    border: none;
    &:enabled:hover,
    &:focus {
      border: none;
      background: darken(#549ed5, 20%);
    }
    .p-button-label {
      font-size: 0.8rem;
    }
  }
}

.form-item {
  &.horizontal {
    display: flex;
    flex-direction: row;
  }
  .p-inputtext {
    font-size: 0.875rem;
    width: 100%;
  }
  .p-button {
    font-size: 0.875rem;
  }
  .p-widget,
  .p-dropdownlist {
    font-size: 0.875rem;
  }
  .p-inputgroup-addon {
    padding: 0;
    .fa-solid {
      font-size: 0.875rem;
    }
  }
}

.p-button {
  font-size: 0.875rem;
}

.p-widget,
.p-widget button,
.p-dropdownlist {
  font-size: 0.875rem;
  background: #fff;
}

.p-dropdownlist,
.p-autocomplete-list-item {
  background: #fff;
}

.p-inputgroup-addon {
  padding: 0;
  .fa-solid {
    font-size: 0.875rem;
  }
}

.tooltip-patients-table {
  .p-tooltip-text {
    word-wrap: break-word;
  }
}

.downscale-font {
  *,
  .p-widget,
  .p-button {
    font-size: 0.875rem;
  }
  .p-dialog-title {
    font-size: 1rem;
  }
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.narrow-col {
  width: 7%;
}

.wide-col {
  width: 14%;
}

.basic-input-group {
  label {
    margin-right: 0.3rem;
  }
}
