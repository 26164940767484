// http://chir.ag/projects/name-that-color/
$dusty-gray: #969696;
$dove-gray: #707070;
$dove-gray-too: #6e6e6e;
$emperor: #4f4f4f;
$polar: #f4f9fd;
$morning-glory: #9ddde4;
$pale-rose: #ffe8f7;
$anakiwa: #acdeff;
$jungle-mist: #b2cfd0;
$sweet-corn: #f8d47f;
$cream-brulee: #ffe19b;
$cornflower-blue: #549ed5;
$jelly-bean: #2770a5;
$bali-hai: #7f98ac;
$oslo-gray: #95989a;
$gallery: #ededed;
$shakespeare: #549ed5;
$wild-sand: #f4f4f4;
$link-water: #e5ebf6;
$chateau-green: #37a862;
$white-lilac: #e5eaf5;
$tundora: #4b4b4b;
$tundora-5: rgba(75, 75, 75, 0.05);
$tundora-30: rgba(75, 75, 75, 0.3);
$tundora-60: rgba(75, 75, 75, 0.6);
$alto: #d9d9d9;
$alto-too: #d5d5d5;
$venice-blue: #074e82;
$venice-blue-10: rgba(7, 78, 130, 0.1);
$venice-blue-20: rgba(7, 78, 130, 0.2);
$venice-blue-50: #84a2bb;
$porcelain: #f5f6f7;
$alabaster: #f8f8f8;
$gallery: #f0f0f0;
$gallery-too: #ebebeb;
$mercury: #e2e2e2;
$alto: #d3d3d3;
$pale-sky: #667081;
$manhattan: #f7c888;
$manhattan-30: rgba(247, 200, 136, 0.3);
$japanese-laurel: #329507;
$la-palma: #2f9a14;
$cinnabar: #e54949;
$curious-blue: #2399e5;
$curious-blue-too: #2c8ed6;
$curious-blue-too-30: rgba(44, 142, 214, 0.3);
$curious-blue-5: rgba(44, 142, 214, 0.05);
$supernova: #ffcc00;
$silver-chalice: #aaaaaa;
$silver-chalice-two-rgba: rgba(159, 159, 159, 1);
$silver-chalice-again: #9f9f9f;
$white: #ffffff;
$black: #000000;
$gray: #868686;
$gray-too: #7e7e7e;
$gray-thrice: #8b8b8b;
$gray-thrice-40: rgba(139, 139, 139, 0.4);
$selago: #f5f8fe;
$blumine: #215080;
$olivine: #90bc68;
$iron: #e0e2e6;
$bittersweet: #ff5d5d;
$bittersweet-too: #ff6f6f;
$persian-red: #d32f2f;
$catskill-white: #e1e7f2;
$mantis: rgb(130, 191, 92);
$mantis-40: rgba($mantis, 0.4);
$clover: #32571b;
$zanah: #d9ebcd;
$light-background: #f4f8ff;
$light-border: #a6a6a6;
$hover-border: #007ad9;
$hover-button-color: #6fa34e;
$border-text-color: #9a9a9a;
$my-schedule-text-color: #676767;
$float-shift-color: #f3f9ee;
$hover-background: #c4d6e6;
$link-hover-background: #0a3b6c;
$pto-shift-submitted: #fdac53;
$pto-shift-approved: #b2d732;
$float-text-button: #9ebcd2;
$float-background-color: #eaf4fb;
$upper-menu-background: #004272;
$publish-notification-bg: #fef3bd;
$published: #ff94c5;
$discontinued: #f4ad64;
$notification-text: #3c3c3c;
$schedule-hedear-bg: #fffce8;
$post-schedule-button: #fdefa7;
$post-container-border-color: #ddcc00;
$post-schedule-button-border-color: #b1b1b1;
$disabled-button-border-color: #b1b1b1;
$disabled-button-color: #f0f0f0;
$calendar-selection: #e6f7fe;
$text-calendar: #212121;
$start-end-date: #84d5fb;
$sub-menu-link: #3c73b5;
$sub-menu-background: #305b94;
$nav-counter: #f6d37d;
$clinic-list-text: #1d3775;
$notes-color: #005390;
$ooc-shift: #f9e8bf;
$req-info: #fdf0ec;
$req-text: #ab1a0f;
$staff-btn: #6fa04e;
$staff-over-max: #e03900;
$error-background: #fcebe5;
$warning-background: #fff7ea;
$warning-cell-corner: #ffad12;
$added-shift-cell: #afafaf;
$added-shift-message: #fffbe0;
$register-border: #d1e2ff;
$register-step-complete-bg: #e5f2f1;
$register-step-complete-border: #cde8dc;
$register-step-complete-font-color: #3ba75d;
$register-component-border: #dbdbdb;
$btn-primary-group-bg: #0f4f81;
$btn-primary-group-border: #2e6da4;
$btn-default-color: #333333;
$btn-default-border: #8c8c8c;
$btn-default-bg: #d4d4d4;
$input-border: #d9d9d9;
$border-form-control: #ccc;
$form-label: #2d6da3;
$add-chair-btn-primary: #337ab7;
$add-chair-border: #e5e5e5;
$add-chair-btn-primary-hover: #286090;
$add-chair-btn-primary-hover-border: #204d74;
$add-chair-btn-secondary: #f8f9fa;
$add-chair-btn-secondary-hover: #e2e6ea;
$pct-staff-type: #4ecdc4;
$rn-staff-type: #be90d4;
$lpn-staff-type: #f09b9b;
$ccht-staff-type: #a0a9dc;
$cht-staff-type: #f491b1;
$rt-staff-type: #7cb442;
$cn-staff-type: #7a86cc;
$cnm-staff-type: #bde0fc;
$sec-staff-type: #afd582;
$aa-staff-type: #f06392;
$bio-staff-type: #65b6f7;
$fa-staff-type: #26a79a;
$sw-staff-type: #0379bd;
$rd-staff-type: #8e26aa;
$ua-staff-type: #0198a8;
$edu-staff-type: #43a047;

$second-pct-staff-type: #edfaf9;
$second-rn-staff-type: #f9f4fb;
$second-lpn-staff-type: #fdf0f0;
$second-ccht-staff-type: #f5f6fc;
$second-cht-staff-type: #fef4f7;
$second-rt-staff-type: #f2f7eb;
$second-cn-staff-type: #f1f3fa;
$second-cnm-staff-type: #f2f9ff;
$second-sec-staff-type: #f7fbf2;
$second-aa-staff-type: #feeff4;
$second-bio-staff-type: #eff8ff;
$second-fa-staff-type: #e9f6f5;
$second-sw-staff-type: #ebf4fa;
$second-rd-staff-type: #f9f4fb;
$second-ua-staff-type: #e5f5f6;
$second-edu-staff-type: #ecf5ec;

$weekend-day: #fafafa;

$border-width-thin: 0.03125rem;
$font-family-sans: 'Open Sans';
$grey-border: #b3b3b3;

$lighter: 300;
$normal: 400;
$semi-bold: 600;
$bold: 700;

$dashboard-met-light: #c0e2c1;
$dashboard-met-dark: #75a93e;
$dashboard-warn-dark: #eead42;
$dashboard-not-met-light: #ffc6cc;
$dashboard-not-met-dark: #c84444;
$dashboard-loading-dark: #0022ff;
$dashboard-loading-light: #848fd7;

$dshbrd-border-color: #dddddd;
$dshbrd-dim-bg-color: #f9f9f9;
$dshbrd-head-bg-color: #eeeeee;

$no-chairs-color: #f48e17;
$no-staff-color: #56c2fa;
$denovo-color: #46ac5a;
$nocturnal-color: #8e86bf;
$error-color: #d93349;
$dark-blue-color: #305b94;
$faded-grey-color: #c1c1c1;
$chart-in-range-color: #2a7cce;
$on-time-color: #66a305;
$on-time-failing-color: #f23719;
$early-color: #448db9;
$late-color: #ca6fd1;
$extreme-color: #6155ea;
$missing-color: #f780d7;

$clinic-selection: #4a4b4b;
$clinic-selection-icon: #006633;
