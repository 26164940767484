@font-face {
  font-family: 'swac-fswicon';
  src: url('../assets/fonts/fswicon.ttf?m0wga5') format('truetype'),
    url('../assets/fonts/fswicon.woff?m0wga5') format('woff'),
    url('../assets/fonts/fswicon.svg?m0wga5#fswicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fsw-icon {
  font-family: 'swac-fswicon' !important;
}

.hep-positive {
  &:before {
    content: '\e905';
    margin-left: 0.375rem;
  }
}
