@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i');

$fontFamily: 'Open Sans', sans-serif;
$fontSize: 1rem;
$borderRadius: 3px;
$disabledOpacity: 0.4;

//Header
$headerBorderWidth: 1px;
$headerBorderColor: #d9d9d9;
$headerBgColor: #f6f7f9;
$headerBgColor2: #dae5ec;
$headerTextColor: #1b1d1f;
$headerTextColor2: #31708f;
$headerTextColor3: #1d64ad;
$headerFontWeight: normal;
$headerIconTextColor: #1b1d1f;

//Content
$contentBorderWidth: 1px;
$contentBorderColor: #d5d5d5;
$contentBgColor: #ffffff;
$contentTextColor: #222222;

//Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #d6d6d6;
$stateDefaultBgColor: #ffffff;
$stateDefaultTextColor: #555555;

//Active State
$stateActiveBorderColor: #bebebe;
$stateActiveBgColor: #d6d6d6;
$stateActiveTextColor: #212121;

//Highlight State
$stateHighlightBorderColor: #156090;
$stateHighlightBgColor: #186ba0;
$stateHighlightTextColor: #ffffff;

//Focus State
$stateFocusBorderColor: #c0c0c0;
$stateFocusBgColor: #eeeeee;
$stateFocusTextColor: #212121;

//Error State
$stateErrorBorderColor: #f44336;
$stateErrorBgColor: #f5554a;
$stateErrorTextColor: #cd0a0a;

//Hover State
$stateHoverBorderColor: #c0c0c0;
$stateHoverBgColor: #eeeeee;
$stateHoverTextColor: #212121;

//Forms
$inputBgColor: #ffffff;
$inputTextColor: #222222;
$invalidInputBorderColor: #f44336;
$inputGroupTextColor: #222222;

$tobasco: #ab1a0f;
$dove-gray: #676767;
