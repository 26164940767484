@import './fp-variables.scss';

i.p-sortable-column-icon.pi.pi-fw.pi-sort {
  color: $dove-gray !important;
}

.open-notes {
  margin-right: 0.5rem;
}

.tooltip-shifts-table {
  .p-tooltip-text {
    word-wrap: break-word;
  }
}

.p-dropdown.p-widget {
  border-color: $bali-hai;
}

.p-dropdown-trigger {
  border-left: 0;
}

.p-inputtext {
  font-size: 0.875rem;
  font-family: $font-family-sans;
}

.p-widget-header {
  background: $wild-sand;
}

.p-button.p-state-default.p-button-label-only {
  border-color: $oslo-gray;
  border-width: $border-width-thin;
  border-radius: 0.125rem;
  font-family: $font-family-sans;
  font-size: 0.75rem;

  &.p-highlight {
    background-color: $shakespeare;
  }
}

body .p-button.p-state-default.p-button-secondary {
  background-color: #dedede;
}

.p-datatable-wrapper {
  font-family: $font-family-sans;
}

.p-datatable-thead {
  font-family: $font-family-sans;
  font-weight: $semi-bold;
}

.p-datatable-wrapper {
  font-size: 0.875rem;
  font-family: $font-family-sans;
}

.p-datatable:not(.no-override) {
  &.p-component {
    padding: 1rem 0;
  }

  & .p-datatable-thead > tr > th {
    // border-top: 0;
    background-color: $white;
    text-align: left;
    font-size: 0.75rem;
    font-weight: $bold;
    color: $dove-gray;
  }

  & .p-datatable-tbody > tr:nth-child(even) {
    background-color: rgba(229, 235, 246, 0.3);
  }

  & .p-sortable-column .p-sortable-column-icon {
    color: $dove-gray;
  }

  & .p-sortable-column.p-state-highlight .p-sortable-column-icon {
    color: $dove-gray;
  }

  & .p-sortable-column .p-sortable-column-icon:hover {
    color: $dove-gray !important;
  }

  & .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: $dove-gray !important;
  }

  & .p-sortable-column.p-state-highlight {
    background-color: $white;
    color: $dove-gray;
  }

  & .p-sortable-column:not(.p-state-highlight):hover {
    background-color: $white;
    color: $dove-gray;
  }

  & .p-datatable-tbody > tr > td {
    border: 0;
    height: 3.125rem;
    font-weight: $semi-bold;
    color: $tundora;
  }
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0px;
  p-table {
    .p-datatable.p-component.p-datatable-responsive-stack {
      padding: 0px;
    }
  }
}

.clinic-card {
  background-color: $gallery;
  border: 0;
  margin: 0.5rem 0;
}

.clinic-card-selected {
  background-color: $mercury;
}

.clinics-manage-card-selected {
  background-color: $gallery;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.capitalize {
  text-transform: capitalize;
}

/* Modals */
.p-dialog.p-widget.p-widget-content.p-corner-all.p-shadow.ng-trigger.ng-trigger-dialogState {
  font-family: $font-family-sans;
  border-radius: 0.3125rem;
  border: 0;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.28);

  & .p-dialog-titlebar-icon span {
    color: $white;
  }

  & .p-dialog-footer {
    border-width: 0;
    border-bottom-left-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }

  & .p-widget-content {
    border-color: $oslo-gray;
  }

  & .p-dialog-titlebar.p-widget-header.p-helper-clearfix.p-corner-top.ng-star-inserted {
    background-color: $venice-blue;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    & .p-dialog-title.ng-star-inserted {
      font-size: 1rem;
      color: $white;
    }
  }

  & .p-button-secondary.p-button {
    height: 1.875rem;
    border-radius: 0.125rem;
    font-weight: $semi-bold;
    border-color: $oslo-gray;
    background-color: $white;
    color: $tundora;
  }

  & .p-button {
    background-color: $chateau-green;
    border-color: $chateau-green;
    color: $white;
    font-size: 0.875rem;
    font-weight: $semi-bold;
    border-radius: 0.125rem;
    height: 1.875rem;
  }

  & .p-datatable-wrapper.ng-star-inserted {
    & th {
      font-weight: $semi-bold;
      font-size: 0.875rem;
    }

    & td {
      font-weight: normal;
      color: $tundora;
    }
  }
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:focus {
  outline: none;
}

body {
  .p-inputtext .p-dropdown {
    border: 0.5px solid #d4d4d4;
  }

  .p-card.clinic-card-selected {
    background-color: #e6e6e6;
  }

  .p-dialog .p-dialog-header {
    color: #333;
    padding: 0.5rem;
  }

  .p-dialog {
    color: $white;
  }
}

.p-datepicker.p-widget .p-datepicker-calendar td a {
  padding: 7px;
}

.p-datepicker.p-widget .p-datepicker-calendar td {
  font-size: 9px;
}

.p-button:focus {
  box-shadow: none;
}

.dashboard-table .p-datatable {
  .p-datatable-tfoot {
    > tr > td {
      background-color: #f1f6fb;
    }
  }
  .p-datatable-thead,
  .p-datatable-tbody,
  .p-datatable-tfoot {
    th {
      font-size: 16px;
    }
    > tr {
      font-size: 16px;
      &:nth-child(even),
      > th {
        background-color: #56c2fa05;
      }
      &:first-child > th {
        background: none;
        padding-bottom: 16px;
      }
      > td,
      > th {
        color: black;
        border: none;
        font-weight: normal;
        border-bottom: 1px solid #dddddd;
        text-align: right;
        &.align-left {
          text-align: left;
        }
        &.align-center {
          text-align: center;
        }
        &.variance {
          background-color: #f48e1710;
        }
      }
    }
  }
}

.help-tooltip.p-overlaypanel {
  z-index: 99;
  font-size: 14px;
  font-weight: 300;
  .p-overlaypanel-content {
    max-width: 320px;
    padding: 20px;
    line-height: 1.25rem;
  }
  .p-overlaypanel-close {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    .p-overlaypanel-close-icon {
      font-size: 0.75rem;
    }
  }
}

.p-editor-content .ql-editor,
.preview-block,
.p-overlaypanel .p-overlaypanel-content {
  .tooltip-content,
  p {
    font-family: $font-family-sans;
    em {
      font-style: italic;
    }
    b,
    strong {
      font-weight: bold;
    }
    a {
      text-decoration: none;
    }
  }
}

.p-editor-content.ql-container .ql-tooltip {
  &[style] {
    left: 0px !important;
    width: 100%;
  }
  a.ql-preview {
    max-width: none;
    width: 340px;
  }
  &.ql-editing input[type='text'] {
    width: 380px;
  }
}

.float-toolbar,
.clear-shifts {
  .p-menubar {
    padding: 0;
    font-size: 0.875rem;
    .p-menubar-custom {
      display: none;
    }
    .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
      padding: 5px 10px;
      > .p-submenu-icon {
        font-size: 0.75rem;
      }
    }
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: $mantis !important;
    &:before {
      background-color: #fff;
    }
    &:hover {
      background-color: darken($mantis, 10) !important;
    }
  }
}

body .registration-container .p-dialog,
fp-register-header .p-dialog {
  h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .p-dialog-titlebar {
    display: none;
  }

  button {
    margin-top: 15px;
    padding: 5px;
  }

  .continue-button {
    background: transparent;
    color: #305b94;
    border: none;

    &:hover {
      background: transparent !important;
      color: #305b94 !important;
      border: none !important;
    }
  }

  .later-button {
    background: #82bf5c;
    margin-right: 15px;
  }
}

.p-treenode-label {
  width: 100%;
}

.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
  visibility: visible !important;
}

.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
  display: none !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background-color: white !important;
  color: green;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: green;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0;
}
