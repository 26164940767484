@import './fp-variables';

$assets: '../assets';

@mixin legendBar($color) {
  content: '';
  width: 0.375rem;
  height: 1.1875rem;
  background: $color;
  display: block;
  float: left;
  margin-right: 0.4375rem;
}

@mixin radius($r) {
  -webkit-border: #{$r}rem;
  -moz-border-radius: #{$r}rem;
  border-radius: #{$r}rem;
}

@mixin iconRight($x: 0.875, $y: 0.875) {
  position: relative;
  top: 0.0625rem;
  display: inline-block;
  height: #{$y}rem;
  width: #{$x}rem;
  line-height: 1;
}

@mixin iconRightBefore() {
  content: url('../assets/arrow-right.svg');
  box-sizing: border-box;
  width: 0.875rem;
  height: 0.875rem;
}
@mixin icon($name, $x: 0.875, $y: 1.25) {
  /* Some of this genius borrowed from bootstrap */
  position: relative;
  top: 0.0625rem;
  display: inline-block;
  height: #{$y}rem;
  width: #{$x}rem;
  background-repeat: no-repeat;
  background-image: url('#{$assets}/#{$name}.svg');
}

@mixin hRule() {
  color: $alto;
  background-color: $alto;
  border: 0;
  height: 0.0625rem;
}

@mixin mobileHeader {
  background-color: $selago;
  border-bottom: 0.0625rem solid $alto;
  border-left: 0.0625rem solid $alto;
  border-right: 0.0625rem solid $alto;
}

@mixin colorButton($color) {
  font-weight: $semi-bold;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  background-color: $color;
  color: $white;
  border: 0.03125rem solid $color;
  @include radius(0.25);
}

@mixin gridFlag($color) {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
  border-top-right-radius: 0.2rem;
  border-width: 0.5rem;
  border-right-color: $color;
  border-top-color: $color;
}

@mixin gridFlagWithoutRadius($color) {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0.5rem;
  border-right-color: $color;
  border-top-color: $color;
}

@mixin launchPadButtons {
  .p-button-success:hover,
  .p-button-success:focus {
    background-color: $hover-button-color;
    border: none;
    box-shadow: none;
  }
  .p-button-success {
    @include colorButton($mantis);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    span {
      font-size: large;
      font-weight: normal;
    }
  }
  .p-button-success:disabled {
    cursor: not-allowed;
  }
}

@mixin color-checkbox($color) {
  .p-checkbox-box {
    background-color: $color !important;
    &.p-state-focus {
      box-shadow: none;
    }
  }
}
