@import './fp-variables';
@import './fp-mixins';

.legend-tooltip {
  max-width: 19.6875rem;
  font-size: 0.875rem;
  & .legend {
    list-style-type: none;
    padding: 0;
    margin: 0;
    & .list-entry {
      padding: 0.5rem 0 0.5rem 0;
    }
    & .discontinued-altered-split:before {
      @include legendBar($anakiwa);
    }
    & .excessive:before {
      @include legendBar($bittersweet-too);
    }
  }
}

.fp-icon-card-right-arrow {
  @include icon('card-arrow-right');
}

.fp-icon-arrow-left {
  @include icon('arrow-left');
}

/*
.fp-icon-arrow-right {
  @include icon("arrow-right");
}
*/
.fp-icon-arrow-right {
  @include iconRight();
}

.fp-icon-arrow-right:before {
  @include iconRightBefore();
}

.fp-icon-blue-arrow-left {
  @include icon('blue-arrow-left');
}

.fp-icon-hamburger {
  @include icon('hamburger');
}

.fp-icon-calendar {
  @include icon('calendar', 1.25, 1.4);
}

.fp-icon-logo {
  @include icon('logo', 6, 1.625);
}

.fp-icon {
  position: relative;
  top: 0.0625rem;
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.fp-btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  display: inline-block;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
}

.fp-btn-lg {
  padding: 0.625rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.375rem;
}

.fp-btn-default {
  color: $black;
  border-color: $gray;
  background-color: $white;
}

.fp-form-control {
  height: 2.125rem;
}

body {
  font-family: 'Open Sans', sans-serif;
  text-rendering: optimizeLegibility;
  font-weight: 300;
  margin: 0;
  padding: 0;
  height: 100%;
  color: $tundora;
}

.tooltip-notes {
  color: $venice-blue;
}

.invalid-full-border.ng-dirty.ng-invalid {
  border-color: $cinnabar;
  border-width: 2px;
}

.input-group {
  display: table;
  position: relative;
  border-collapse: separate;

  & .form-control {
    display: table-cell;
  }
}

.input-group-addon {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}
.input-group-addon:first-child {
  border-right: 0;
}
