@import 'variables';
//@import "../../../node_modules/primeng/resources/themes/primeng";

/** BEGIN patch for primeng 5 -> 7 update (pulled from 5.x primeng.css) **/
/* Error background */
.p-messages-error {
  color: #ab1a0f;
  background-color: #ffcbc8;
  border-color: #ffcbc8;
}
/* Severity Buttons */
/* Secondary */
.p-button.p-button-secondary.p-state-default,
.p-splitbutton.p-button-secondary .p-button.p-state-default {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #373a3c;
}

.p-button.p-button-secondary:enabled:hover,
.p-button.p-button-secondary:focus,
.p-splitbutton.p-button-secondary .p-button:enabled:hover,
.p-splitbutton.p-button-secondary .p-button:focus {
  background-color: #f2f2f2;
  border-color: #cccccc;
  color: #373a3c;
}

.p-button.p-button-secondary:enabled:active,
.p-splitbutton.p-button-secondary .p-button:enabled:active {
  background-color: #e6e6e6;
  border-color: #cccccc;
  color: #373a3c;
}

/* Success */
.p-button.p-button-success.p-state-default,
.p-splitbutton.p-button-success .p-button.p-state-default {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #ffffff;
}

.p-button.p-button-success:enabled:hover,
.p-button.p-button-success:focus,
.p-splitbutton.p-button-success .p-button:enabled:hover,
.p-splitbutton.p-button-success .p-button:focus {
  background-color: #4cae4c;
  border-color: #5cb85c;
}

.p-button.p-button-success:enabled:active,
.p-splitbutton.p-button-success .p-button:enabled:active {
  background-color: #449d44;
  border-color: #5cb85c;
}

/* Info */
.p-button.p-button-info.p-state-default,
.p-splitbutton.p-button-info .p-button.p-state-default {
  background-color: #5bc0de;
  border-color: #5bc0de;
  color: #ffffff;
}

.p-button.p-button-info:enabled:hover,
.p-button.p-button-info:focus,
.p-splitbutton.p-button-info .p-button:enabled:hover,
.p-splitbutton.p-button-info .p-button:focus {
  background-color: #46b8da;
  border-color: #5bc0de;
}

.p-button.p-button-info:enabled:active,
.p-splitbutton.p-button-info .p-button:enabled:active {
  background-color: #31b0d5;
  border-color: #5bc0de;
}

/* Warning */
.p-button.p-button-warning.p-state-default,
.p-splitbutton.p-button-warning .p-button.p-state-default {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  color: #ffffff;
}

.p-button.p-button-warning:enabled:hover,
.p-button.p-button-warning:focus,
.p-splitbutton.p-button-warning .p-button:enabled:hover,
.p-splitbutton.p-button-warning .p-button:focus {
  background-color: #eea236;
  border-color: #f0ad4e;
}

.p-button.p-button-warning:enabled:active,
.p-splitbutton.p-button-warning .p-button:enabled:active {
  background-color: #ec971f;
  border-color: #f0ad4e;
}

/* Danger */
.p-button.p-button-danger.p-state-default,
.p-splitbutton.p-button-danger .p-button.p-state-default {
  background-color: #d9534f;
  border-color: #d9534f;
  color: #ffffff;
}

.p-button.p-button-danger:enabled:hover,
.p-button.p-button-danger:focus,
.p-splitbutton.p-button-danger .p-button:enabled:hover,
.p-splitbutton.p-button-danger .p-button:focus {
  background-color: #d43f3a;
  border-color: #d9534f;
}

.p-button.p-button-danger:enabled:active,
.p-splitbutton.p-button-danger .p-button:enabled:active {
  background-color: #c9302c;
  border-color: #d9534f;
}
.p-calendar {
  position: relative;
  display: inline-block;
}

.p-calendar .p-calendar-button {
  position: absolute;
  height: 100%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 2em;
  border-left: 0 none;
}

.p-calendar .p-calendar-button:enabled:hover,
.p-calendar .p-calendar-button:focus {
  border-left: 0 none;
}
/** END patch for primeng 5 -> 7 update **/

.p-widget-header {
  background: #f6f7f9 0 0 repeat-x;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f6f7f9), color-stop(100%, #ebedf0));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* Opera11.10+ */
  background: -ms-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
  /* W3C */
}

.p-accordion {
  .p-accordion-header {
    background: $headerBgColor;
    border-top: 1px solid $headerBorderColor;
    a {
      color: $headerTextColor;
    }
    &:not(.p-highlight):not(.p-state-disabled):hover {
      background: #ededf0;
    }
    &.p-highlight {
      background: #ffffff;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
  }
  .p-accordion-content {
    border: 0 none;
  }
}

/* Tabview */

.p-tabview {
  &.p-widget-content {
    border: 0 none;
  }
  .p-tabview-nav {
    background: transparent;
    > li {
      &.p-state-default {
        background: #f6f7f9;
      }
      &.p-highlight {
        background: #ffffff;
        font-weight: normal;
        color: #555555;
      }
      &:not(.p-highlight):not(.p-state-disabled):hover {
        background: #ededf0;
      }
    }
  }
}

/* Spinner */

.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:hover {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff;
}

.p-spinner:not(.p-state-disabled) .p-spinner-button:enabled:active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}

.p-slider {
  position: relative;
  text-align: left;
  background: #838688;
  border: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
}

.p-slider .p-slider-handle {
  position: absolute;
  z-index: 2;
  width: 17px !important;
  height: 21px !important;
  cursor: default;
  background: url('images/slider_handles.png') 0 0 no-repeat;
  outline: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}

.p-slider .p-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  background: #14a4ff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.p-slider .p-slider-handle.p-highlight {
  background-position: -17px 0;
}

.p-slider-horizontal {
  height: 6px;
}

.p-slider-horizontal .p-slider-handle {
  top: -2px !important;
  margin-left: -0.6em;
}

.p-slider-horizontal .p-slider-range {
  top: 0;
  height: 100%;
}

.p-slider-horizontal .p-slider-range-min {
  left: 0;
}

.p-slider-horizontal .p-slider-range-max {
  right: 0;
}

.p-slider-vertical {
  width: 0.8em;
  height: 100px;
}

.p-slider-vertical .p-slider-handle {
  left: -0.1em !important;
  margin-left: 0;
  margin-bottom: -0.6em;
}

.p-slider-vertical .p-slider-range {
  left: 0;
  width: 100%;
}

.p-slider-vertical .p-slider-range-min {
  bottom: 0;
}

.p-slider-vertical .p-slider-range-max {
  top: 0;
}

.p-progressbar {
  &.p-progressbar-determinate {
    .p-progressbar-value {
      border: 0 none;
      background: #8ec5fc;
    }
    .p-progressbar .p-progressbar-label {
      color: #222222;
    }
  }
  &.p-progressbar-indeterminate {
    background: #8ec5fc;
    .p-progressbar-value {
      border: 0 none;
      background-color: $stateHighlightBgColor;
    }
  }
}

.p-widget-header .p-button,
.p-widget-content .p-button,
.p-widget.p-button,
.p-button {
  border: 1px solid #0f4f81;
  color: #ffffff;
  background: #0f4f81;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
  &:enabled:hover,
  &:focus {
    border: 1px solid #1f89ce;
    background: #1f89ce;
    outline: 0 none;
    color: #ffffff;
  }
  &:enabled:active {
    border: 1px solid #156090;
    background: #186ba0;
    color: #ffffff;
  }
}

.p-button-label {
  font-weight: 300;
  font-style: normal;
}

.p-fileupload-choose:not(.p-state-disabled):hover,
.p-fileupload-choose.p-state-focus {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff;
}

.p-fileupload-choose:not(.p-state-disabled):active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}

/* Checkbox and Radio */

.p-chkbox-box.p-highlight,
.p-radiobutton-box.p-highlight {
  border: 1px solid #95989a;
  background: #95989a;
  color: #ffffff;
}

.p-chkbox-box.p-state-focus,
.p-radiobutton-box.p-state-focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.p-chkbox-box.p-state-focus.p-highlight {
  background: #95989a;
}

.p-chkbox-box:not(.p-state-disabled):not(.p-highlight):hover {
  border: 1px solid #d6d6d6;
  background: #ffffff;
  color: #555555;
}

/* Inputs */

.p-inputtext {
  background: #ffffff;
  color: #667081;
  border: 1px solid #d9d9d9;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.p-inputtext.p-state-focus,
.p-inputtext:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* InputSwitch */

.p-inputswitch-on {
  background: #186ba0 !important;
  color: #ffffff !important;
}

.p-paginator .p-paginator-page.p-highlight {
  background: #186ba0;
  color: #ffffff;
  border-color: #156090;
}

/* DataTable  */

.p-datatable {
  th {
    &.p-state-default {
      background: #ebedf0;
      border-color: #d9d9d9;
    }
    &.p-sortable-column:not(.p-highlight):hover {
      background: #d3d5d8;
      border-color: #d9d9d9;
    }
    &.p-highlight {
      background: #186ba0;
      color: #ffffff;
    }
  }
  tbody {
    > tr.p-widget-content {
      border-color: #d9d9d9;
      &.p-datatable-odd {
        background-color: #fafafb;
      }
      &.p-state-highlight {
        background-color: $stateHighlightBgColor;
        color: $stateHighlightTextColor;
      }
    }
  }
  tfoot {
    td {
      &.p-state-default {
        background: #ebedf0;
        border-color: #d9d9d9;
      }
    }
  }
}

/* Panel */

.p-panel.p-widget {
  padding: 0;
  .p-panel-titlebar.p-corner-all {
    -moz-border-radius-bottom-left: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottom-right: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-panel-titlebar {
    border-width: 0 0 1px 0;
  }
  .p-panel-titlebar-icon span {
    position: relative;
    top: 1px;
  }
}

/* TreeTable */

.p-treetable {
  th.p-state-default {
    background: #ebedf0;
    border-color: #d9d9d9;
  }
}

/* ButtonSet */

.p-togglebutton.p-button.p-state-default,
.p-selectbutton .p-button.p-state-default,
.p-selectbutton .p-button:not(.p-highlight) {
  border: 1px solid #d6d6d6;
  background: #ffffff;
  font-weight: normal;
  color: #555555;
}

.p-togglebutton.p-button.p-state-hover,
.p-togglebutton.p-button.p-state-focus,
.p-selectbutton .p-button.p-state-hover,
.p-selectbutton .p-button.p-state-focus {
  border: 1px solid #c0c0c0;
  background: #eeeeee;
  font-weight: normal;
  color: #212121;
}

.p-togglebutton.p-button.p-state-focus,
.p-selectbutton .p-button.p-state-focus.p-highlight {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.p-togglebutton.p-button.p-highlight,
.p-selectbutton .p-button.p-highlight {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff;
}

.p-multiselect {
  .p-multiselect-label {
    background-color: #ffffff;
  }
}

.p-dropdown.p-state-focus,
.p-multiselect.p-state-focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Growl */

.p-growl-item-container.p-state-highlight {
  &.p-growl-message-info {
    background-color: #2196f3;
    border-color: #2196f3;
  }
  &.p-growl-message-error {
    background-color: #f44336;
    border-color: #f44336;
  }
  &.p-growl-message-warn {
    background-color: #ffb300;
    border-color: #ffb300;
  }
  &.p-growl-message-success {
    background-color: #4caf50;
    border-color: #4caf50;
  }
}

/* TabMenu */

.p-tabmenu {
  border: 0 none;
  .p-tabmenu-nav {
    background: none;
    > li {
      &.p-state-default {
        background: #f6f7f9;
      }
      &.p-highlight {
        background: #ffffff;
        font-weight: normal;
        color: #555555;
      }
      &:not(.p-highlight):not(.p-state-disabled):hover {
        background: #ededf0;
      }
    }
  }
}

/* Menus */

.p-menu,
.p-menu .p-menu-child {
  border: 1px solid #d9d9d9;
  color: #1b1d1f;
  background: #f6f7f9 0 0 repeat-x;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f6f7f9), color-stop(100%, #ebedf0));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* Opera11.10+ */
  background: -ms-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
  /* W3C */
}

.p-menu {
  .p-menuitem {
    .p-menuitem-link {
      &:hover {
        background-color: #a6a6a6;
        color: #ffffff;
      }
    }
    &.p-menuitem-active {
      > .p-menuitem-link {
        background-color: #a6a6a6;
        color: #ffffff;
      }
    }
  }
}

/* PanelMenu */

.p-panelmenu .p-panelmenu-header.p-highlight,
.p-panelmenu .p-panelmenu-header.p-highlight a {
  border-color: #156090;
  background: #186ba0;
  color: #ffffff;
}

/* DatePicker */

.p-datepicker.p-widget {
  padding: 0;
  .p-datepicker-header {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    a {
      &:hover {
        border-width: 1px;
      }
    }
  }
  .p-datepicker-calendar {
    margin: 0;
    thead th {
      background-color: #f6f8fa;
      padding: 8px;
    }
    td {
      border-bottom: 1px solid rgba(213, 213, 213, 0.5);
      padding: 0;
      a {
        border: 0 none;
        text-align: center;
        padding: 8px;
        &.p-state-highlight {
          background-color: #d6d6d6;
          color: #212121;
        }
        &.p-highlight {
          background-color: #186ba0;
          color: #ffffff;
        }
      }
    }
    tr:last-child td {
      border-bottom: 0 none;
    }
  }
  .p-timepicker {
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  &.p-datepicker-timeonly {
    .p-timepicker {
      border-top: 0 none;
    }
  }
}

/* Steps */

.p-steps .p-steps-item.p-state-highlight .p-menuitem-link {
  color: #ffffff;
}

/* Dialog */

.p-dialog.p-widget .p-dialog-titlebar {
  padding: 1em 1.5em;
}

.p-dialog.p-widget .p-dialog-titlebar .p-dialog-title {
  font-size: 1.25em;
}

.p-dialog.p-widget .p-dialog-content {
  padding: 1em 1.5em;
}

/* Schedule */

.fc {
  .fc-button-group {
    .p-highlight {
      border: 1px solid #156090;
      background: #186ba0;
      color: #ffffff;
    }
  }
}

.timeout-dialog {
  .p-widget-header {
    background: #0f4f81;
  }
  .p-dialog-title {
    color: #fff;
    font-weight: 600;
  }
  .p-dialog-titlebar {
    background: #0f4f81;
  }
  .p-widget-content {
    border: none;
  }
  .p-dialog-content {
    .p-confirmdialog-message > span {
      font-weight: 600;
    }
  }
}
