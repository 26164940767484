@font-face {
  font-family: 'fp-fswicon';
  src: url('/client/assets/fonts/fp-fswicon.eot?kl5ncq');
  src: url('/client/assets/fonts/fp-fswicon.eot?kl5ncq#iefix') format('embedded-opentype'),
    url('/client/assets/fonts/fp-fswicon.ttf?kl5ncq') format('truetype'),
    url('/client/assets/fonts/fp-fswicon.woff?kl5ncq') format('woff'),
    url('/client/assets/fonts/fp-fswicon.svg?kl5ncq#fswicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icomoon-icon-'],
[class*=' icomoon-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fp-fswicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-icon-blue-arrow-left::before {
  content: '\e901';
}
.icomoon-icon-calendar-nav::before {
  content: '\e902';
}
.icomoon-icon-card-arrow-left::before {
  content: '\e903';
}
.icomoon-icon-card-arrow-right::before {
  content: '\e904';
}
.icomoon-icon-clinic-search::before {
  content: '\e905';
}
.icomoon-icon-hamburger::before {
  content: '\e906';
}
.icomoon-icon-open-shift::before {
  content: '\e907';
}
.icomoon-icon-update-profile::before {
  content: '\e908';
}
.icomoon-icon-fresenius-logo::before {
  content: '\e909';
}
.icomoon-icon-x-close::before {
  content: '\e90a';
}
.icomoon-icon-do-open-shifts::before {
  content: '\e90b';
}
.icomoon-icon-scheduled-shifts::before {
  content: '\e90c';
}
.icomoon-icon-staff-list::before {
  content: '\e90d';
}
.icomoon-icon-staff-schedule::before {
  content: '\e90e';
}
.icomoon-icon-long-arrow-left::before {
  content: '\e90f';
}
.icomoon-icon-long-arrow-right::before {
  content: '\e910';
}
.icomoon-icon-staff-disabled::before {
  content: '\e911';
}
.icomoon-icon-cm-open-shifts::before {
  content: '\e912';
}
.icomoon-icon-staff::before {
  content: '\e913';
}
.icomoon-icon-plus::before {
  content: '\e935';
}
.icomoon-icon-edit::before {
  content: '\e900';
}

.custom-icon {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;

  &.icon-anchor {
    background: url('/client/assets/Anchor.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-assist {
    background: url('/client/assets/Assist.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-av-fistula {
    background: url('/client/assets/AVFistula.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-catheter {
    background: url('/client/assets/Catheter.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-graft {
    background: url('/client/assets/Graft.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-hep-pos {
    background: url('/client/assets/HepPos.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-high-acuity {
    background: url('/client/assets/HighAcuity.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-hoyer-lift {
    background: url('/client/assets/HoyerLift.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-integrated-care {
    background: url('/client/assets/Integrated-Care.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-new-patient {
    background: url('/client/assets/NewPatient.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-transient {
    background: url('/client/assets/Transient.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-wheelchair {
    background: url('/client/assets/Wheelchair.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-wheelchair {
    background: url('/client/assets/Wheelchair.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.icon-bell {
    background: url('/client/assets/Bell.svg');
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
