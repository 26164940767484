@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

html,
body {
  width: 100%;
  height: 100%;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility;
  font-weight: 300;
  margin: 0;
  padding: 0;
  height: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.invalid-full-border .ng-dirty.ng-invalid {
  border-color: #f44336;
}

p-calendar.ng-dirty.ng-invalid .invalid-full-border,
p-autocomplete.ng-dirty.ng-invalid .invalid-full-border {
  border-color: #f44336;
}

div.no-ul-border ul {
  border: none;
}

.dd {
  width: 12rem;
}

.full-width {
  width: 100%;
}

.center-col {
  text-align: center !important;
}

.add-to-clinic.p-button {
  background: #549ed5;
  border: none;
}
.add-to-clinic.p-button:enabled:hover, .add-to-clinic.p-button:focus {
  border: none;
  background: #266a9d;
}
.add-to-clinic.p-button .p-button-label {
  font-size: 0.8rem;
}

.capacity-assistance {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0.7em 0.5em 0 0.5em;
}
.capacity-assistance.p-button {
  width: 100%;
  background: #549ed5;
  border: none;
}
.capacity-assistance.p-button:enabled:hover, .capacity-assistance.p-button:focus {
  border: none;
  background: #266a9d;
}
.capacity-assistance.p-button .p-button-label {
  font-size: 0.8rem;
}

.form-item.horizontal {
  display: flex;
  flex-direction: row;
}
.form-item .p-inputtext {
  font-size: 0.875rem;
  width: 100%;
}
.form-item .p-button {
  font-size: 0.875rem;
}
.form-item .p-widget,
.form-item .p-dropdownlist {
  font-size: 0.875rem;
}
.form-item .p-inputgroup-addon {
  padding: 0;
}
.form-item .p-inputgroup-addon .fa-solid {
  font-size: 0.875rem;
}

.p-button {
  font-size: 0.875rem;
}

.p-widget,
.p-widget button,
.p-dropdownlist {
  font-size: 0.875rem;
  background: #fff;
}

.p-dropdownlist,
.p-autocomplete-list-item {
  background: #fff;
}

.p-inputgroup-addon {
  padding: 0;
}
.p-inputgroup-addon .fa-solid {
  font-size: 0.875rem;
}

.tooltip-patients-table .p-tooltip-text {
  word-wrap: break-word;
}

.downscale-font *,
.downscale-font .p-widget,
.downscale-font .p-button {
  font-size: 0.875rem;
}
.downscale-font .p-dialog-title {
  font-size: 1rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.narrow-col {
  width: 7%;
}

.wide-col {
  width: 14%;
}

.basic-input-group label {
  margin-right: 0.3rem;
}

@font-face {
  font-family: "swac-fswicon";
  src: url("../assets/fonts/fswicon.ttf?m0wga5") format("truetype"), url("../assets/fonts/fswicon.woff?m0wga5") format("woff"), url("../assets/fonts/fswicon.svg?m0wga5#fswicon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.fsw-icon {
  font-family: "swac-fswicon" !important;
}

.hep-positive:before {
  content: "\e905";
  margin-left: 0.375rem;
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }
}
.tooltip-body {
  padding: 4px;
  font-size: 0.7rem;
  line-height: 0.9rem;
}

.tooltip-subject {
  font-size: 0.8rem;
  font-weight: bold;
}

.tooltip-hepBStatus {
  display: inline-block;
  bottom: 8px;
  margin-top: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
}