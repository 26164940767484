.filter-wrapper {
  float: left;

  .clinic-list-dropdown {
    width: 250px;
  }

  &.hidden {
    display: none;
  }
}

.ht-app-container {
  width: 100%;
}

.control-section {
  display: flex;
  position: relative;
  margin: 20px 2.5%;
  top: 4.3125rem;

  &.section-header {
    top: 10.25rem;
  }

  .schedule-container {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.ht-event-modal {
  width: 1140px !important;
  margin: 10px auto;
  min-height: 350px !important;
  max-height: none !important;

  .e-float-text.e-label-top,
  .e-end-on-label,
  .e-week-expander-label {
    font-weight: 300 !important;
    font-size: 14px !important;
    color: rgb(51, 51, 51) !important;
  }

  .half {
    width: 50%;
    float: left;
  }

  .third {
    width: 33%;
    float: left;
  }

  .right-spacer {
    padding-right: 15px;
  }

  .left-spacer {
    padding-left: 15px;
  }

  .appt-row {
    margin-bottom: 15px;
    clear: both;
    float: left;
    width: 100%;

    &.border-bottom {
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
    }

    label {
      margin-bottom: 5px;
      display: inline-block;
    }
  }
}

.timeline-resource.e-schedule .e-timeline-view .e-date-header-wrap table col,
.timeline-resource.e-schedule .e-timeline-view .e-content-wrap table col {
  width: 60px !important;
}

.timeline-resource.e-schedule .template-wrap > div {
  overflow: visible !important;
}

.e-filter-icon.expanded span {
  background: url('../assets/icon-filter-collapse.svg') no-repeat center;
}

.e-filter-icon span {
  background: url('../assets/icon-filter-expand.svg') no-repeat center;
}

.e-close-icon::before {
  content: '\e361';
}

.schedule-print.e-schedule .e-schedule-toolbar .e-icons {
  font-family: 'Schedule_print', 'e-icons';
}

.e-icon-schedule-print {
  background-size: 15px 15px !important;
  background: url('../assets/icon-print.svg') right center no-repeat;
}

.e-icon-schedule-export {
  background-size: 15px 15px !important;
  background: url('../assets/icon-export.svg') right center no-repeat;
}

.schedule-print.e-schedule .e-schedule-toolbar .e-toolbar-item.e-today {
  display: none;
}

.mrn-search-container .e-input-group.e-control-wrapper {
  border-radius: 4px 0 0 4px;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td {
  vertical-align: bottom;
  width: 200px;
}

.timeline-resource.e-schedule.e-device .e-timeline-view .e-resource-left-td {
  width: 75px;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
  display: flex;
  font-weight: 500;
  padding: 0;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  flex: 0 0 50%;
  font-weight: 500;
  height: 36px;
  line-height: 34px;
  padding-left: 5px;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div:last-child {
  border-right: 0;
}

.timeline-resource.e-schedule .template-wrap {
  display: flex;
  height: 100%;
  text-align: left;

  .name.bold {
    font-weight: 700;
  }
}

.timeline-resource.e-schedule .template-wrap > div {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex: 0 0 100%;
  font-weight: 400;
  line-height: 58px;
  overflow: hidden !important;
  padding-left: 5px;
  text-overflow: ellipsis;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text > div {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  flex: 0 0 100%;
  font-weight: 400;
  height: 36px;
  line-height: 34px;
  padding-left: 5px;
}

.e-schedule .e-timeline-view .e-navigate {
  font-size: 16px !important;
}

.timeline-resource.e-schedule .template-wrap > div:last-child {
  border-right: 0;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-cells,
.timeline-resource.e-schedule .e-timeline-month-view .e-resource-cells {
  padding-left: 0;
}

.timeline-resource.e-schedule .e-read-only {
  opacity: 0.8;
}

.e-tooltip-wrap {
  &.e-popup {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 2px #ddd;
    overflow: hidden;
  }

  .e-tip-content {
    color: black;
  }
}

.quick-popup-container {
  padding: 1rem;
  clear: both;

  p {
    font-size: 13px;
    color: #707070;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .quick-popup-label {
    font-weight: 400;
    min-width: 75px;
    display: inline-block;
    margin-right: 20px;
  }

  .e-header-icon-wrapper {
    padding: 20px;
  }
}

.subject-container {
  float: left;
}

.e-popup-header {
  padding: 10px 20px 0 20px;
}

.icon-container {
  float: right;
}

.e-quick-popup-wrapper .e-popup-footer {
  padding: 0;
}

.quick-popup-title {
  font-size: 18px;
  color: #4b4b4b;
  font-weight: 700;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-content {
  padding: 0 18px 0 26px;
}

.e-schedule {
  overflow: visible;
}

.e-appointment.e-lib.e-draggable {
  overflow: hidden;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer {
  float: left;
  padding: 0px 40px 20px 40px;
}
/***** Recurrence Editor Overrides *****/
.ht-event-modal .e-dlg-content,
#htScheduler_dialog_wrapper_dialog-content {
  padding-top: 0 !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.e-schedule-dialog .e-footer-content {
  margin-top: 0;
}

.e-recurrenceeditor .e-input-wrapper {
  width: 100%;
}

.e-recurrenceeditor .e-input-wrapper-side {
  width: 100%;
}

.e-recurrenceeditor .e-form-right,
.e-recurrenceeditor .e-input-wrapper-side.e-form-right {
  padding: 0 0 10px 0;
}

.e-recurrenceeditor .e-input-wrapper-side.e-end-on .e-end-on-left,
.e-recurrenceeditor .e-input-wrapper-side.e-end-on .e-end-on-left {
  padding-right: 0;
}

.e-recurrenceeditor .e-input-wrapper.e-end-on-date,
.e-recurrenceeditor .e-input-wrapper.e-end-on-count {
  padding-top: 10px;
}

.e-recurrenceeditor .e-days button {
  margin: 0 5px 10px;
}

.e-schedule .e-timeline-view .e-appointment .e-recurrence-icon,
.e-schedule .e-timeline-view .e-appointment .e-recurrence-edit-icon {
  position: absolute;
  right: 0;
  line-height: 15px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

.e-quick-popup-wrapper.e-popup-open .quick-popup-container .ng-star-inserted p {
  word-wrap: break-word;
}

.e-quick-popup-wrapper.e-popup-open .e-popup-header .subject-container {
  width: 90%;
  word-wrap: break-word;
}
