/* You can add global styles to this file, and also import other style files */

@import './swac-reset';
@import './primeng-overrides';
@import './glyph-icons';

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }
}

.tooltip-body {
  padding: 4px;
  font-size: 0.7rem;
  line-height: 0.9rem;
}

.tooltip-subject {
  font-size: 0.8rem;
  font-weight: bold;
}

.tooltip-hepBStatus {
  display: inline-block;
  bottom: 8px;
  margin-top: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
}
