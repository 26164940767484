/* Borrowed some ideas from Bootstrap */
.row {
  display: flex;
  flex-wrap: wrap;
}

/* Flex syntax:
   flex: flex-grow | flex-shrink | flex-basis
*/
.col-50 {
  flex: 0 0 50%;
}

.col-100 {
  flex: 0 0 100%;
}

.justify-content-between {
  justify-content: space-between !important;
}
.align-item-end {
  align-self: flex-end !important;
}

.align-item-start {
  align-self: flex-start !important;
}

.align-item-center {
  align-self: center !important;
}

.align-items-center {
  align-items: center;
}
